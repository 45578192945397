<template>
  <div class="home" v-loading="loading">
    <div class="topsearch" style="padding-bottom:8px; padding-left:6px;">
      <el-radio-group v-model="type" size="small" @change="changeType">
        <el-radio-button :label="1" >今日</el-radio-button>
        <el-radio-button :label="2">本周</el-radio-button>
        <el-radio-button :label="3">本月</el-radio-button>
        <el-radio-button :label="5">总计</el-radio-button>
        <el-radio-button :label="4">自定义</el-radio-button>
        
      </el-radio-group>
      <el-date-picker
        v-if="type==4"
        style="margin-left:8px;"
        v-model="value1"
        type="daterange"
        @change="changeDate"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="toptj">
      <el-row>
        <!-- <el-col :span="4"><div class="topcont"><strong style="color:#ff704f;">{{todayAdd}}</strong><p>今日新增简历数</p></div></el-col> -->
        <el-col :span="5"><div class="topcont"><strong>{{totalResume}}</strong><p>{{status}}简历数</p></div></el-col>
        <el-col :span="5"><div class="topcont"><strong>{{totalMember}}</strong><p>{{status}}企业数</p></div></el-col>
        <el-col :span="5"><div class="topcont"><strong>{{totalPositions}}</strong><p>{{status}}岗位数</p></div></el-col>
        <el-col :span="5"><div class="topcont"><strong>{{reachAnIntention}}</strong><p>达成求职意向数</p></div></el-col>
        <!-- <el-col :span="4"><div style="cursor: pointer; " class="topcont" @click="$router.push({path:'/intention',query:{s:1}})"><strong>{{reachAnIntentionDay}}</strong><p>今日达成求职意向数</p></div></el-col> -->
        <el-col :span="4"><div class="topcont"><strong>30w+</strong><p>用工需求人数</p></div></el-col>
      </el-row>
    </div>
    <div class="notice">
      <div class="hometitle"><i></i>即将开始招聘会</div>
      <div class="noticecont">
        <div class="listitem" v-for="(item,index) in list">
          <div class="noticeleft">
            <strong>{{item.jobName}}</strong>
            <span>时间：{{item.holdStartTime}} 至 {{item.holdEndTime}}</span>
          </div>
        </div>
        
      </div>
    </div>
    <!-- <div class="history">
      <div class="hometitle"><i></i>历史招聘会</div>
      <div class="charts">
        <div id="chartsbox" style="width:100%; height:400px;"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
const apiurl="/homepage/query"
import _api from "@/api/index.js"

export default {
  data(){
    return {
      status:"新增",
      value1:[],
      startTime:null,
      endTime:null,
      type:1,
      jobNotice:{id:null,jobName:null,holdEndTime:null,holdStartTime:null,companiesNum:0},
      todayAdd:0,
      totalMember:0,
      totalPositions:0,
      totalResume:0,
      reachAnIntentionDay:0,
      reachAnIntention:0,
      imgurl:"",
      title:"这里是标题",
      show:false,
      list:[],
      loading:false,
      model:{
        title:"测试标题",
        url:""
        //url:"http://pic.quxingcm.com/ad5cdf84-be95-46e0-afd2-395f779b9512.jpg"
      },
      options:{
        grid: {
              top: '12%',
              left: '1%',
              right: '1%',
              bottom: '3%',
              containLabel: true
            },
        legend: {},
        tooltip: {},
        dataset: {
                  source: [
                     
                  ]
              },
              xAxis: {type: 'category'},
              yAxis: {},
              // Declare several bar series, each will be mapped
              // to a column of dataset.source by default.
              series: [
                  {
                    type: 'bar',
                    barWidth : 15,
                    itemStyle:{
                        normal:{
                            color:'#3968d0'
                        }
                    },
                  },
                  {
                    type: 'bar',
                    barWidth : 15,
                    itemStyle:{
                        normal:{
                            color:'#ff704f'
                        }
                    },
                  },{
                    type: 'bar',
                    barWidth : 15,
                    itemStyle:{
                        normal:{
                            color:'#5cb87a'
                        }
                    },
                  },
              ]
          }
    }
  },
  created(){
    this.getDetail()
    this.getList()
  },
  mounted(){
   // this.drawLine()
  },
  methods:{
    changeType(){
      if(this.type!=4){
        this.value1=[]
        this.startTime=null
        this.endTime=null
        
      }
      if(this.type==5){
          this.status="活跃"
        }else{
          this.status="新增"
        }
      this.getDetail()
    },
    changeDate(){
      if(this.value1 && this.value1.length>0){
        this.startTime=this.value1[0]
        this.endTime=this.value1[1]
      }else{
        this.startTime=null
        this.endTime=null
      }
      this.getDetail()
    },
    getDetail(){
      this.loading=true
      _api.get(apiurl,{type:this.type,startTime:this.startTime,endTime:this.endTime}).then(res=>{
        this.loading=false
          console.log(res)
        //  var tjdata=[['product', '预约企业数', '职位数', '求职者人数']]
         if(res.code!=undefined && res.code==200){
           this.todayAdd=res.data.todayAdd
           this.totalMember=res.data.totalMember
           this.totalResume=res.data.totalResume
           this.totalPositions=res.data.totalPositions
           this.reachAnIntention=res.data.reachAnIntention
           this.reachAnIntentionDay=res.data.reachAnIntentionDay
           this.jobNotice.id=res.data.jobNotice.id
           this.jobNotice.jobName=res.data.jobNotice.jobName
           this.jobNotice.holdEndTime=res.data.jobNotice.holdEndTime
           this.jobNotice.holdStartTime=res.data.jobNotice.holdStartTime
           this.jobNotice.companiesNum=res.data.jobNotice.companiesNum
          this.list=res.data.jobNotice
          // for(let item of res.data.jobHistory){
          //   tjdata.push([item.jobName,item.companiesNum,item.workNum,item.positionNum])
          // }
          // this.options.dataset.source=tjdata

          // let myChart = this.$echarts.init(document.getElementById("chartsbox"));
          // myChart.setOption(this.options);

         }
      })
    },
    getList(){
      
    },
    getimgurl(data){
      this.model.url=data
    },
    getclose(data){
      this.show=data
    },
    getconfirm(data){
      // console.log(this.title)
    },
    
  }
}
</script>
<style scoped>
.topsearch{ display: flex; align-items: center;}
.listitem{ margin:25px 0;}
.topcont{ background: #ffffff; padding: 15px; text-align: center; border-radius:4px; margin-right:5px;margin-left:5px; height: 100px;}
.topcont strong{ display: block; font-size: 30px; font-weight: normal; padding-top: 3px;}
.topcont p{ padding-top: 12px; color: #888888;}
.notice{ background: #ffffff;border-radius: 8px; margin:0 5px; padding:15px; margin-top:15px;}
.hometitle{ font-weight: bold; font-size: 16px;}
.hometitle i{ display: inline-block; width: 4px; height: 15px; background: #3968d0; vertical-align: middle; margin-top: -3px; margin-right:6px;}
.noticecont{ padding-top: 28px; padding-bottom: 15px;overflow: hidden;}
.noticeleft strong{ color: #787878;}
.noticeleft span{ color: #acacac; margin-left:18px;}
.noticeright{float: right; color: #acacac;}
.noticeright em{color: #ff704f; font-style: normal; font-weight: bold;}
.history{background: #ffffff;border-radius: 4px; margin:0 5px; padding:15px; margin-top:15px;}
.charts{ padding-top: 15px;}
</style>
